import moment from "moment";
const moments = require("moment-timezone");
export const convertTo24Hour = (time12h: string) => {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");

  if (modifier === "PM" && hours !== "12") {
    hours = String(parseInt(hours, 10) + 12);
  }
  if (modifier === "AM" && hours === "12") {
    hours = "00";
  }

  return `${hours}:${minutes}`;
};

export const convertDateFormat = (date: any) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export  const envOptions = [
    { label: "Dev", value: "dev" },
    { label: "QA", value: "qa" },
    { label: "Stage", value: "beta" },
  ];
  export const UTCToLocalTimes = (utcTime:any, timezone:any) => {
    // Create a full date using today's date and the given time in UTC
    const todayUTC = `${moment().format("YYYY-MM-DD")}T${utcTime}:00Z`; // Ensure to append 'Z' for UTC

    // Convert UTC time to the specified timezone and format it as hh:mm
    const convertedTime = moments.tz(todayUTC, timezone).format("HH:mm");

    return convertedTime;
};

export const convertToUtc = (time: any, date: any, timezone: any) => {
  if (time && date && timezone) {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const datetime = moment.tz(
      `${formattedDate} ${time}`,
      "YYYY-MM-DD HH:mm",
      timezone
    );
    const utcDate = datetime.utc().format("YYYY-MM-DD");
    const utcTime = datetime.utc().format("HH:mm");
    return { utcTime, utcDate };
  }
};