import React, { SVGProps } from 'react';

// Paginator next arrow icon

const PaginatorNextArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="6"
        height="8"
        viewBox="0 0 6 8"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.76711 7.81586C0.537434 7.577 0.544882 7.19718 0.783745 6.9675L3.93395 4L0.783745 1.0325C0.544881 0.802823 0.537434 0.422997 0.76711 0.184134C0.996786 -0.0547285 1.37661 -0.0621767 1.61548 0.167499L5.21548 3.5675C5.33312 3.68062 5.39961 3.83679 5.39961 4C5.39961 4.16321 5.33312 4.31938 5.21548 4.4325L1.61548 7.8325C1.37661 8.06218 0.996786 8.05473 0.76711 7.81586Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default PaginatorNextArrow;
