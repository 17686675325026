import { VariantProps, cva } from "class-variance-authority";
import React, { useState } from "react";
import { cn } from "../utils";
import DropDownIcon from "../assets/DropDownIcon";
import "../index.css";
import clsx from "clsx";
import ErrorIcon from "../assets/ErrorIcon";
interface DropDownProps extends VariantProps<typeof dropdownVariants> {
  name?: string;
  placeholder?: string;
  errors?: boolean;
  value?: any;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  errorMessage?: string;
  helpText?: string;
  label?: string;
  options: { value: any; label: string }[];
  disabled?: boolean;
}
const dropdownVariants = cva(
  "px-4 py-2 w-full h-10 text-base rounded border outline-none font-medium font-custom",
  {
    variants: {
      variant: {
        primary:
          "border-neutral-200 focus-visible:border-primary-600 focus:ring focus:ring-primary-200",
        danger:
          "border-error-700 focus-visible:border-error-700 focus:ring focus:ring-error-200",
        disable: `opacity-50 bg-neutral-100 cursor-not-allowed text-neutral-700 pointer-events-none`,
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  }
);

const DropDown = ({
  variant,
  name,
  placeholder,
  errors,
  onChange,
  value,
  errorMessage,
  helpText,
  label,
  options,
  disabled,
}: DropDownProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  // Function to transform text by replacing commas with line breaks
  const formatHelpText = (text?: string) => {
    if (!text) return "";
    return text.split(",").map((part, index) => (
      <React.Fragment key={index}>
        {part.trim()}
        <br />
      </React.Fragment>
    ));
  };
  return (
    <div>
      {label && (
        <>
          <label className="flex float-left text-neutral-950 text-base font-medium">
            {label}
          </label>
          <br />
        </>
      )}

      <div className="flex flex-col gap-2 relative mt-2">
        <div className="relative">
          <select
            value={value}
            onChange={onChange}
            className={clsx(
              cn(dropdownVariants({ variant }), "custom-select"),
              {
                "text-neutral-900": value === "",
                "text-neutral-950": value !== "",
              }
            )}
            name={name}
            disabled={disabled}
          >
            <option value="" disabled={true} selected>
              {placeholder}
            </option>
            {options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                className="text-neutral-950"
              >
                {option.label}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none">
            {disabled === true ? (
              <DropDownIcon className="text-neutral-300" />
            ) : (
              <DropDownIcon className="text-neutral-400" />
            )}
          </div>
        </div>
        {helpText && (
          <div
            className="absolute right-[-30px] top-2 cursor-pointer"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <ErrorIcon className="text-2xl text-neutral-300" />
            {showTooltip && (
              <div className=" flex items-center leading-6 absolute z-10 py-3 px-5 right-0 md:left-6 top-2 mb-2 w-[300px] md:w-max  text-sm text-neutral-950 bg-neutral-0 border border-neutral-200 rounded shadow-lg">
                {formatHelpText(helpText)}
              </div>
            )}
          </div>
        )}
        {errors && (
          <div className="flex items-center gap-2">
            <ErrorIcon className="text-xl text-error-700" />
            <p className=" text-base font-medium text-error-700">
              {errorMessage}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;
