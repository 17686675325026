import React from "react";
import ReactPaginate from "react-paginate";
import "./paginator.css";
import PaginatorBackArrow from "../assets/PaginatorBackArrow";
import PaginatorNextArrow from "../assets/PaginatorNextArrow";
// Paginator component - Facilitates switching between pages within a table.

interface IpaginationProps {
  pageCount: number;
  onPageChange: any;
  initialPage: number;
}

const Pagination = (props: IpaginationProps) => {
  return (
    <ReactPaginate
      className="custom-paginate"
      breakLabel="..."
      nextLabel={
        <span>
          Next <PaginatorNextArrow className="ml-1" />
        </span>
      }
      onPageChange={props.onPageChange}
      pageRangeDisplayed={4}
      pageCount={props.pageCount}
      previousLabel={
        <span>
          <PaginatorBackArrow className="mr-1" /> Back
        </span>
      }
      initialPage={props.initialPage}
    />
  );
};

export default Pagination;
