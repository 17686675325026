import type { SVGProps } from 'react';
const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg "
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12.9951 8.17549C12.9951 7.62321 12.5474 7.17549 11.9951 7.17549C11.4428 7.17549 10.9951 7.62321 10.9951 8.17549V11.8245C10.9951 12.3768 11.4428 12.8245 11.9951 12.8245C12.5474 12.8245 12.9951 12.3768 12.9951 11.8245V8.17549ZM11.9951 14.8245C11.4428 14.8245 10.9951 15.2722 10.9951 15.8245C10.9951 16.3768 11.4428 16.8245 11.9951 16.8245H12.0051C12.5574 16.8245 13.0051 16.3768 13.0051 15.8245C13.0051 15.2722 12.5574 14.8245 12.0051 14.8245H11.9951Z"
      fill="currentColor"
    />
  </svg>
);
export default ErrorIcon;
