import React, { useState } from "react";
import Card from "../../../components/Themecard";
import DropDown from "../../../components/DropDownInput";
import constants from "../../../constants/contents.json";
import Button from "../../../components/Button";
import TimePicker from "../../../components/TimePicker";
import moment from "moment";
import { UTCToLocalTimes } from "../../../services/utils";
interface IPayloadInterface {
  handleDropdownChange: any;
  handleMachineDetailsChange: any;
  removeMachine: any;
  addMachine: any;
  selectedValues: any;
  timezone:any;

}
const LocationCard = (props: IPayloadInterface) => {
  const location = constants.location;
  const locationRange = constants.locationRange;
  const machineRange = constants.machineNames;

  // current time-----------------
  const currentTime = moment().format("HH:mm");

  return (
    <Card>
      <div className="grid grid-cols-2">
        <DropDown
          label={location.location}
          placeholder={location.selectLocation}
          options={locationRange}
          onChange={(e) =>
            props.handleDropdownChange("location", e.target.value)
          }
          helpText={location.details}
        />
      </div>
      {props.selectedValues.locationMachineDetails.map(
        (machine: any, index: any) => (
          <div key={index} className="grid grid-cols-4 gap-4 mt-4">
            <DropDown
              label={`Machine *`}
              placeholder="Select Machine"
              options={machineRange}
              onChange={(e) =>
                props.handleMachineDetailsChange(
                  "locationMachineDetails",
                  index,
                  "name",
                  e.target.value
                )
              }
            />
            <DropDown
              label="Zone *"
              placeholder="Select Zone"
              options={constants.zoneRange}
              onChange={(e) =>
                props.handleMachineDetailsChange(
                  "locationMachineDetails",
                  index,
                  "location",
                  e.target.value
                )
              }
            />
            <div>
              <div>
                <TimePicker
                  label="Select Time"
                  value={machine.start_time}
                  onChange={(e) => {
                    const selectedTime = e.target.value;
                    // const utcTime = moment(selectedTime, "HH:mm")
                    //   .utc()
                    //   .format("HH:mm");
                    props.handleMachineDetailsChange(
                      "locationMachineDetails",
                      index,
                      "start_time",
                      selectedTime
                    );
                  }}
                />
              </div>
            </div>
            <div className="mt-8">
              <Button
                variant="secondary"
                onClick={() =>
                  props.removeMachine("locationMachineDetails", index)
                }
              >
                Remove
              </Button>
            </div>
          </div>
        )
      )}
      <div className="mt-5">
        <Button
          variant="primary"
          onClick={() => props.addMachine("locationMachineDetails")}
        >
          Add Machine
        </Button>
      </div>
    </Card>
  );
};

export default LocationCard;
