import { createHashRouter, RouterProvider } from "react-router-dom";
import RiskTriggerPage from "./pages/Providers/viam/RiskTriggerPage";
import SimulatorDashboard from "./pages/SimulatorDashboard";
import RouteConstants from "./constants/RouteConstant.json";
import IonRiskTriggerPage from "./pages/Providers/ion/IonRiskTriggerPage";
import History from "./pages/History/History";
import AnalyticsSimulator from "./pages/Analytics/AnalyticsSimulator";

function App() {
  const router = createHashRouter([
    {
      path: RouteConstants.DASHBOARD,
      element: <SimulatorDashboard />,
    },
    {
      path: RouteConstants.VIAM,
      element: <RiskTriggerPage />,
    },
    {
      path: RouteConstants.ION,
      element: <IonRiskTriggerPage />,
    },
    { path: RouteConstants.HISTORY, element: <History /> },
    { path: RouteConstants.ANALYTICS, element: <AnalyticsSimulator /> },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
