import React, { SVGProps } from 'react';

// paginator back arrow icon

const PaginatorBackArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="6"
        height="8"
        viewBox="0 0 6 8"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.23289 0.184136C5.46257 0.422999 5.45512 0.802824 5.21626 1.0325L2.06605 4L5.21625 6.9675C5.45512 7.19718 5.46257 7.577 5.23289 7.81587C5.00321 8.05473 4.62339 8.06218 4.38452 7.8325L0.784524 4.4325C0.666877 4.31938 0.600389 4.16321 0.600389 4C0.600389 3.83679 0.666877 3.68062 0.784524 3.5675L4.38452 0.167501C4.62339 -0.0621752 5.00321 -0.0547276 5.23289 0.184136Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default PaginatorBackArrow;
