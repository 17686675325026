import React, { ChangeEvent, useEffect, useState } from "react";

// Define the interface for the TimePicker props
interface TimePickerProps {
  value: string; // Current value of the time input
  onChange: (event: ChangeEvent<HTMLInputElement>) => void; // Handler for input changes
  label?: string;
}

const TimePicker: React.FC<TimePickerProps> = ({ value, onChange, label }) => {
  const [currentTime, setCurrentTime] = useState(value);

  useEffect(() => {
    // If no value is provided, set the current time
    if (!value) {
      updateCurrentTime();
    } else {
      setCurrentTime(value);
    }
  }, [value]);

  // Function to update the current time
  const updateCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    setCurrentTime(`${hours}:${minutes}`);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event); // Call the onChange prop function
    setCurrentTime(event.target.value); // Update the current time state
  };

  return (
    <form className="max-w-[8rem] cursor-pointer">
      <label className="text-neutral-950 text-base font-medium">{label}</label>
      <input
        type="time"
        id="time"
        className="bg-gray-50 p-2 mt-2 leading-none text-sm rounded-lg focus-visible:outline-none border"
        value={currentTime}
        onChange={handleChange}
        required
        onKeyDown={(e) => e.preventDefault()}  
      />
    </form>
  );
};

export default TimePicker;
