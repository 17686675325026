import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import Constants from "../../../constants/contents.json";
import BackArrow from "../../../assets/backArrow";
import { useLocation, useNavigate } from "react-router-dom";
import RouteConstants from "../../../constants/RouteConstant.json";
import MachinePermissionCard from "./MachinePermissionCard";
import MachineConfigurationCard from "./MachineConfigurationCard";
import MachineCalibartionCard from "./MachineCalibrationCard";
import { TriggerEvent } from "../../../services/Api";
import Toast from "../../../components/Toast";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import moment from "moment-timezone";
import DropDown from "../../../components/DropDownInput";
import { convertToUtc } from "../../../services/utils";

interface MachineDetails {
  name: string;
  location: string;
  start_time: string;
  start_date: string;
  provider: string;
  value: string;
}

type CardType =
  | "machineAuthorizationDetails"
  | "machineConfigurationDetails"
  | "machineCalibrationDetails";
interface SelectedValues {
  machineAuthorization: string;
  machineConfiguration: string;
  machineCalibration: string;
  machineAuthorizationDetails: MachineDetails[];
  machineConfigurationDetails: MachineDetails[];
  machineCalibrationDetails: MachineDetails[];
}
type AlertType = "success" | "error" | "information" | "warning";

const IonRiskTriggerPage = () => {
  const navigate = useNavigate();
  const Location = useLocation();
  const simulateDetails = Location.state;
  // convert date format
  const convertDateFormat = (date: any) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(dateObj.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  // -----------------------------------------------------------------------
  // ---------------------------------------timezone------------
  const moments = require("moment-timezone");
  const timeZones = moments.tz.names();

  // Convert timeZones into options format
  const timeZoneOptions = timeZones.map((tz: any) => ({
    value: tz,
    label: tz,
  }));
  const [selectedTimezone, setSelectedTimezone] = useState("US/Pacific");
  const today = moments().tz(selectedTimezone).startOf("day").toDate();
  const [selectedDate, setSelectedDate] = useState();

  const sevenDaysAgo = subDays(today, 6); // Set to 7 days ago
  const minDate = sevenDaysAgo;
  const maxDate = today;
  const handleTimezoneChange = (e: any) => {
    setSelectedTimezone(e.target.value);
    setSelectedDate(today);
  };

  // convert to utc
  // const convertToUtc = (time: any, date: any, timezone: any) => {
  //   // if (time) {
  //   //   const utcTime = moment.tz(time, "HH:mm", timezone).utc().format("HH:mm");
  //   //   return utcTime;
  //   // }

  // };


  useEffect(() => {
    setSelectedDate(today);
  }, [selectedTimezone]);
  // -------------timezone----------------------
  // current time-----------------
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const currentTime = moment(`${hours}:${minutes}`, "HH:mm").utc();
  const currentLocalTime = currentTime.tz(selectedTimezone).format("HH:mm");

  // ------------------------------------------------
  const [showAlertToast, setShowAlertToast] = useState(false);
  const [alertToastType, setAlertToastType] = useState<AlertType>("success");
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedValues, setSelectedValues] = useState<SelectedValues>({
    machineAuthorization: "",
    machineConfiguration: "",
    machineCalibration: "",
    machineAuthorizationDetails: [
      {
        name: "",
        location: "",
        start_time: currentLocalTime,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    machineConfigurationDetails: [
      {
        name: "",
        location: "",
        start_time: currentLocalTime,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    machineCalibrationDetails: [
      {
        name: "",
        location: "",
        start_time: currentLocalTime,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
  });
  // for setting start time for different timezone when time zone changes
  useEffect(() => {
    setSelectedValues({
      machineAuthorization: "",
      machineConfiguration: "",
      machineCalibration: "",
      machineAuthorizationDetails: [
        {
          name: "",
          location: "",
          start_time: currentLocalTime,
          start_date: convertDateFormat(selectedDate),
          provider: simulateDetails.provider,
          value: "",
        },
      ],
      machineConfigurationDetails: [
        {
          name: "",
          location: "",
          start_time: currentLocalTime,
          start_date: convertDateFormat(selectedDate),
          provider: simulateDetails.provider,
          value: "",
        },
      ],
      machineCalibrationDetails: [
        {
          name: "",
          location: "",
          start_time: currentLocalTime,
          start_date: convertDateFormat(selectedDate),
          provider: simulateDetails.provider,
          value: "",
        },
      ],
    });
  }, [selectedTimezone]);

  // function to get  machine details change on dropdown
  const handleMachineDetailsChange = (
    cardType: CardType,
    index: number,
    key: keyof MachineDetails,
    value: string
  ) => {
    const updatedMachineDetails = selectedValues[cardType].map((machine, i) =>
      i === index ? { ...machine, [key]: value } : machine
    );
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [cardType]: updatedMachineDetails,
    }));
  };

  // function to get risk details on dropdwon change
  const handleDropdownChange = (key: keyof SelectedValues, value: string) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  // Function to add machine
  const addMachine = (cardType: CardType) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [cardType]: [
        ...prevValues[cardType],
        {
          name: "",
          location: "",
          start_time: currentLocalTime,
          start_date: convertDateFormat(selectedDate),
          provider: simulateDetails.provider,
          value: "",
        },
      ],
    }));
  };

  // function to remove machine
  const removeMachine = (cardType: CardType, index: number) => {
    const updatedMachineDetails = selectedValues[cardType].filter(
      (_, i) => i !== index
    );
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [cardType]: updatedMachineDetails,
    }));
  };

  // converting data to post  through API
  const formatDataForSubmission = () => {
    const data = [
      {
        event: "Authorization",
        value: selectedValues.machineAuthorization,
        machine_details: selectedValues.machineAuthorizationDetails.map(
          (machine) => ({
            ...machine,
            start_date: convertDateFormat(selectedDate),
            value: selectedValues.machineAuthorization, // Adding machinePermission value
          })
        ),
      },
      {
        event: "Configuration",
        value: selectedValues.machineConfiguration,
        machine_details: selectedValues.machineConfigurationDetails.map(
          (machine) => ({
            ...machine,
            start_date: convertDateFormat(selectedDate),
            value: selectedValues.machineConfiguration, // Adding machinePermission value
          })
        ),
      },
      {
        event: "Calibration",
        value: selectedValues.machineCalibration,
        machine_details: selectedValues.machineCalibrationDetails.map(
          (machine) => ({
            ...machine,
            start_date: convertDateFormat(selectedDate),
            value: selectedValues.machineCalibration, // Adding machinePermission value
          })
        ),
      },
    ];

    // Format the data for posting to the API
    const formattedData = data
      .filter((item) => item.value !== "")
      .map((item) => ({
        risk: {
          domain: simulateDetails.domain,
          event: item.event,
          value: item.value,
          provider: simulateDetails.provider,
          date: convertDateFormat(selectedDate),
        },
        machine_details: item.machine_details
          .filter((machine) => machine.name !== "")
          .map((machine) => {
            const utcResult = convertToUtc(
              machine.start_time,
              selectedDate,
              selectedTimezone
            ); // Call convertToUtc
            // Check if utcResult is valid
            const utcTime = utcResult?.utcTime || "";
            const utcDate = utcResult?.utcDate || "";
            return {
              ...machine,
              start_time: utcTime, // Include start_time in the machine details
              start_date: utcDate, // Use utcDate as needed
            };
          }),
      }));

    return {
      events: formattedData,
    };
  };

  const validateSubmission = () => {
    const formattedData = formatDataForSubmission();
    // Ensure at least one event is selected and all events have at least one machine detail
    const hasValidEvents =
      formattedData.events.length > 0 &&
      !formattedData.events.some((event) => event.machine_details.length === 0);
    // Ensure a domain is selected
    const hasValidDomain = formattedData.events.some(
      (event) => event.risk.domain !== ""
    );

    // Return true only if all conditions are met
    return hasValidEvents && hasValidDomain;
  };

  const isSubmitDisabled = !validateSubmission();
  // api call for risk trigger
  const handleSubmit = async () => {
    const formattedData = formatDataForSubmission();
    setLoading(true);
    try {
      const response = await TriggerEvent(
        simulateDetails.domain,
        simulateDetails.environment,
        formattedData
      );
      if (response?.data?.detail === "success") {
        setAlertToastType("success");
        setToastMessage(Constants.successMsg);
        setShowAlertToast(true);
        setLoading(false);
        setSelectedValues({
          machineAuthorization: "",
          machineConfiguration: "",
          machineCalibration: "",
          machineAuthorizationDetails: [
            {
              name: "",
              location: "",
              start_time: currentLocalTime,
              start_date: convertDateFormat(selectedDate),
              provider: simulateDetails.provider,
              value: "",
            },
          ],
          machineConfigurationDetails: [
            {
              name: "",
              location: "",
              start_time: currentLocalTime,
              start_date: convertDateFormat(selectedDate),
              provider: simulateDetails.provider,
              value: "",
            },
          ],
          machineCalibrationDetails: [
            {
              name: "",
              location: "",
              start_time: currentLocalTime,
              start_date: convertDateFormat(selectedDate),
              provider: simulateDetails.provider,
              value: "",
            },
          ],
        });
      }
    } catch (error: any) {
      setAlertToastType("error");
      setShowAlertToast(true);
      if (error.response) {
        if (error.response.status === 400) {
          setToastMessage(Constants.dataMissing);
        }
      } else {
        setToastMessage(Constants.ErrorMsg);
      }
      setLoading(false);
    }
  };

  // function to cancel toast
  const onClickCancelToast = () => {
    setShowAlertToast(false);
  };
  if (loading === true) return <Loader />;

  return (
    <>
      <div className="px-10 md:px-24 my-10">
        <div className="flex justify-between flex-wrap">
          <div className="flex gap-4 items-center">
            <BackArrow
              className="cursor-pointer"
              onClick={() => navigate(RouteConstants.DASHBOARD)}
            />
            <h1 className="text-lg font-semibold ">
              <span className="text-neutral-500">Provider: </span>
              <span className="text-neutral-900">First Resonance </span>
            </h1>
          </div>
          <div className="flex gap-3 justify-center items-center">
            <DropDown
              placeholder="Select Timezone"
              options={timeZoneOptions}
              onChange={handleTimezoneChange}
              value={selectedTimezone}
            />
            <div>
              <DatePicker
                selected={selectedDate}
                onChange={(date: any) => setSelectedDate(date)}
                dateFormat="MMMM d, yyyy"
                minDate={minDate}
                maxDate={maxDate}
                placeholderText="Select a date"
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <MachinePermissionCard
            addMachine={addMachine}
            removeMachine={removeMachine}
            selectedValues={selectedValues}
            handleDropdownChange={handleDropdownChange}
            handleMachineDetailsChange={handleMachineDetailsChange}
            timezone={selectedTimezone}
          />
        </div>
        <div className="mt-5">
          <MachineConfigurationCard
            addMachine={addMachine}
            removeMachine={removeMachine}
            selectedValues={selectedValues}
            handleDropdownChange={handleDropdownChange}
            handleMachineDetailsChange={handleMachineDetailsChange}
            timezone={selectedTimezone}
          />
        </div>
        <div className="mt-5">
          <MachineCalibartionCard
            addMachine={addMachine}
            removeMachine={removeMachine}
            selectedValues={selectedValues}
            handleDropdownChange={handleDropdownChange}
            handleMachineDetailsChange={handleMachineDetailsChange}
            timezone={selectedTimezone}
          />
        </div>

        <div className="mt-10">
          <Button
            variant={isSubmitDisabled ? "disable" : "primary"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
        {showAlertToast && (
          <div className="fixed bottom-2 left-40 w-full z-50">
            <Toast
              showToast={showAlertToast}
              Message={toastMessage}
              cancelToast={onClickCancelToast}
              type={alertToastType}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default IonRiskTriggerPage;
