import type { SVGProps } from 'react';
const Information = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9H12.01C12.5623 9 13.01 8.55228 13.01 8C13.01 7.44772 12.5623 7 12.01 7H12ZM11 11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13V16C11 16.5523 11.4477 17 12 17H13C13.5523 17 14 16.5523 14 16C14 15.4477 13.5523 15 13 15V12C13 11.4477 12.5523 11 12 11H11Z"
      fill="white"
    />
  </svg>
);
export default Information;
