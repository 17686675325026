import axios from "axios";

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use((req) => {
  req.headers["Content-Type"] = "application/json";
  return req;
});

export { axiosInstance };

export const TriggerEvent = (domain: string, env: string, data: any) => {
  return axiosInstance.post(
    `https://${domain}.${env}.kamet.ai/api/event/trigger/`,
    data
  );
};

export const getDomainList = (env: string) => {
  return axiosInstance.get(`https://admin.${env}.kamet.ai/api/domain/list/`);
};

export const getEventsList = (
  env: string,
  domain: string,
  machine: string,
  pageNumber: number | null
) => {
  return axiosInstance.get(
    `https://${domain}.${env}.kamet.ai/api/events/history/?name=${machine}${
      pageNumber ? `&page=${pageNumber}` : ""
    }`
  ); //TODO: change api end point
};

// get provider list
export const getProviderList = (env: string, domain: string) => {
  return axiosInstance.get(`https://${domain}.${env}.kamet.ai/api/provider/`);
};

// update event
export const updateEventStatus = (domain: string, env: string, data: any) => {
  return axiosInstance.post(
    `https://${domain}.${env}.kamet.ai/api/events/status/change/`,
    data
  );
};

// update event
export const updateAnalytics = (domain: string, env: string, data: any) => {
  return axiosInstance.post(
    `https://${domain}.${env}.kamet.ai/api/events/analytics/`,
    data
  );
};

// update event
export const resetEvents = (domain: string, env: string) => {
  return axiosInstance.delete(
    `https://${domain}.${env}.kamet.ai/api/events/reset/data/`
  );
};
