import Card from "../../components/Themecard";
import Input from "../../components/InputComponent/Input";

interface IuptimeCard {
  staticData: any;
  onChange: (field: string, value: number) => void; 
}

const UptimeCard = (props: IuptimeCard) => {
  return (
    <>
      <section>
        <h2 className="text-lg font-semibold text-neutral-950 mb-2">
          {props.staticData.title}
        </h2>
        <Card>
          <div>
            <p className="text-neutral-950 text-base font-medium">
              {props.staticData.numberOfMachinesLabel}
            </p>
            <div className="grid grid-cols-3">
              <Input
                placeholder={props.staticData.numberOfMachinesPlaceholder}
                type="number"
                helpText={props.staticData.helpText}
                onchange={(e) =>
                  props.onChange("uptime_hours_weekly", Number(e.target.value))
                }
              />
            </div>
            <div className="grid grid-cols-3 mt-5">
              <Input
                label={props.staticData.percentageLabel}
                placeholder={props.staticData.percentagePlaceholder}
                type="number"
                onchange={(e) =>
                  props.onChange("uptime_percent_previous", Number(e.target.value))
                }
              />
            </div>
          </div>
        </Card>
      </section>
    </>
  );
};

export default UptimeCard;
