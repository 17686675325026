import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Toast from "../../../components/Toast";
import PayloadCard from "./PayloadCard";
import SpeedCard from "./SpeedCard";
import LocationCard from "./LocationCard";
import SafetyCard from "./SafetySensor";
import CameraCard from "./CameraSensor";
import VersionCard from "./VersionCard";
import constants from "../../../constants/contents.json";
import { TriggerEvent } from "../../../services/Api";
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import RouteConstants from "../../../constants/RouteConstant.json";
import BackArrow from "../../../assets/backArrow";
import Loader from "../../../components/Loader";
import moment from "moment";
import DropDown from "../../../components/DropDownInput";
import { convertToUtc } from "../../../services/utils";
interface MachineDetails {
  name: string;
  location: string;
  start_time: string;
  start_date: string;
  provider: string;
  value: string;
}

type CardType =
  | "payloadMachineDetails"
  | "speedMachineDetails"
  | "locationMachineDetails"
  | "safetySensorDetails"
  | "cameraSensorDetails"
  | "versionDetails";

interface SelectedValues {
  payload: string;
  speed: string;
  location: string;
  safetySensor: string;
  cameraSensor: string;
  version: string;
  domain: string;
  payloadMachineDetails: MachineDetails[];
  speedMachineDetails: MachineDetails[];
  locationMachineDetails: MachineDetails[];
  safetySensorDetails: MachineDetails[];
  cameraSensorDetails: MachineDetails[];
  versionDetails: MachineDetails[];
}

const RiskTriggerPage = () => {
  
  
  const navigate = useNavigate();
  const Location = useLocation();
  const simulateDetails = Location.state;
  type AlertType = "success" | "error" | "information" | "warning";
  // ---------------------------------------timezone------------
  const moments = require("moment-timezone");
  const timeZones = moments.tz.names();

  // Convert timeZones into options format
  const timeZoneOptions = timeZones.map((tz: any) => ({
    value: tz,
    label: tz,
  }));
  const [selectedTimezone, setSelectedTimezone] = useState("US/Pacific");
  const today = moments().tz(selectedTimezone).startOf("day").toDate();
  const [selectedDate, setSelectedDate] = useState();
  const sevenDaysAgo = subDays(today, 6); // Set to 7 days ago
  const minDate = sevenDaysAgo;
  const maxDate = today;
  const handleTimezoneChange = (e: any) => {
    setSelectedTimezone(e.target.value);
    setSelectedDate(today);
  };
  useEffect(() => {
    setSelectedDate(today);
  }, [selectedTimezone]);
  // current time-----------------

  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const currentTime = moment(`${hours}:${minutes}`, "HH:mm").utc();
  const currentTimeInUtc = currentTime.tz(selectedTimezone).format("HH:mm");

  
  // -------------timezone----------------------
  // date converting function ------------------------------
  const convertDateFormat = (date: any) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(dateObj.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  // -----------------------------------------------------------
  const [showAlertToast, setShowAlertToast] = useState(false);
  const [alertToastType, setAlertToastType] = useState<AlertType>("success");
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState<SelectedValues>({
    payload: "",
    speed: "",
    location: "",
    safetySensor: "",
    cameraSensor: "",
    version: "",
    payloadMachineDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    speedMachineDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    locationMachineDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    safetySensorDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    cameraSensorDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    versionDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    domain: "",
  });
 // for setting start time for different timezone when time zone changes
 useEffect(() => {
  setSelectedValues({
    payload: "",
    speed: "",
    location: "",
    safetySensor: "",
    cameraSensor: "",
    version: "",
    payloadMachineDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    speedMachineDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    locationMachineDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    safetySensorDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    cameraSensorDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    versionDetails: [
      {
        name: "",
        location: "",
        start_time: currentTimeInUtc,
        start_date: convertDateFormat(selectedDate),
        provider: simulateDetails.provider,
        value: "",
      },
    ],
    domain: "",
  });
}, [selectedTimezone]);
  const handleDropdownChange = (key: keyof SelectedValues, value: string) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleMachineDetailsChange = (
    cardType: CardType,
    index: number,
    key: keyof MachineDetails,
    value: string
  ) => {
    const updatedMachineDetails = selectedValues[cardType].map((machine, i) =>
      i === index ? { ...machine, [key]: value } : machine
    );

    setSelectedValues((prevValues) => ({
      ...prevValues,
      [cardType]: updatedMachineDetails,
    }));
  };

  const addMachine = (cardType: CardType) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [cardType]: [
        ...prevValues[cardType],
        {
          name: "",
          location: "",
          start_time: currentTimeInUtc,
          start_date: convertDateFormat(selectedDate),
          provider: simulateDetails.provider,
          value: "",
        },
      ],
    }));
  };

  const removeMachine = (cardType: CardType, index: number) => {
    const updatedMachineDetails = selectedValues[cardType].filter(
      (_, i) => i !== index
    );
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [cardType]: updatedMachineDetails,
    }));
  };
  const onClickCancelToast = () => {
    setShowAlertToast(false);
  };

  const formatDataForSubmission = () => {
    const data = [
      {
        event: "Software",
        value: selectedValues.version,
        machine_details: selectedValues.versionDetails.map((machine) => ({
          ...machine,
          start_date: convertDateFormat(selectedDate),
          value: selectedValues.version, // Adding machinePermission value
        })),
      },
      {
        event: "Payload",
        value: selectedValues.payload,
        machine_details: selectedValues.payloadMachineDetails.map(
          (machine) => ({
            ...machine,
            start_date: convertDateFormat(selectedDate),
            value: selectedValues.payload, // Adding machinePermission value
          })
        ),
      },
      {
        event: "Speed",
        value: selectedValues.speed,
        machine_details: selectedValues.speedMachineDetails.map((machine) => ({
          ...machine,
          start_date: convertDateFormat(selectedDate),

          value: selectedValues.speed, // Adding machinePermission value
        })),
      },
      {
        event: "Location",
        value: selectedValues.location,
        machine_details: selectedValues.locationMachineDetails.map(
          (machine) => ({
            ...machine,
            start_date: convertDateFormat(selectedDate),
            value: selectedValues.location, // Adding machinePermission value
          })
        ),
      },
      {
        event: "Safety Sensor",
        value: selectedValues.safetySensor,
        machine_details: selectedValues.safetySensorDetails.map((machine) => ({
          ...machine,
          start_date: convertDateFormat(selectedDate),
          value: selectedValues.safetySensor, // Adding machinePermission value
        })),
      },
      {
        event: "Camera Sensor",
        value: selectedValues.cameraSensor,
        machine_details: selectedValues.cameraSensorDetails.map((machine) => ({
          ...machine,
          start_date: convertDateFormat(selectedDate),
          value: selectedValues.cameraSensor, // Adding machinePermission value
        })),
      },
    ];

    const formattedData = data
      .filter((item) => item.value !== "")
      .map((item) => ({
        risk: {
          domain: simulateDetails.domain,
          event: item.event,
          value: item.value,
          provider: simulateDetails.provider,
          date: convertDateFormat(selectedDate),
        },
        machine_details: item.machine_details
        .filter((machine) => machine.name !== "")
        .map((machine) => {
          const utcResult = convertToUtc(
            machine.start_time,
            selectedDate,
            selectedTimezone
          ); // Call convertToUtc
          // Check if utcResult is valid
          const utcTime = utcResult?.utcTime || "";
          const utcDate = utcResult?.utcDate || "";
          return {
            ...machine,
            start_time: utcTime, // Include start_time in the machine details
            start_date: utcDate, // Use utcDate as needed
          };
        }),
      }));

    return {
      events: formattedData,
    };
  };

  const validateSubmission = () => {
    const formattedData = formatDataForSubmission();
    // Ensure at least one event is selected and all events have at least one machine detail
    const hasValidEvents =
      formattedData.events.length > 0 &&
      !formattedData.events.some((event) => event.machine_details.length === 0);
    // Ensure a domain is selected
    const hasValidDomain = formattedData.events.some(
      (event) => event.risk.domain !== ""
    );

    // Return true only if all conditions are met
    return hasValidEvents && hasValidDomain;
  };

  const isSubmitDisabled = !validateSubmission();
  // api call for risk trigger
  const handleSubmit = async () => {
    const formattedData = formatDataForSubmission();
    setLoading(true);
    try {
      const response = await TriggerEvent(
        simulateDetails.domain,
        simulateDetails.environment,
        formattedData
      );
      if (response?.data?.detail === "success") {
        setAlertToastType("success");
        setToastMessage(constants.successMsg);
        setShowAlertToast(true);
        setLoading(false);
        setSelectedValues({
          payload: "",
          speed: "",
          location: "",
          safetySensor: "",
          cameraSensor: "",
          version: "",
          payloadMachineDetails: [
            {
              name: "",
              location: "",
              start_time: currentTimeInUtc,
              start_date: convertDateFormat(selectedDate),
              provider: simulateDetails.provider,
              value: "",
            },
          ],
          speedMachineDetails: [
            {
              name: "",
              location: "",
              start_time: currentTimeInUtc,
              start_date: convertDateFormat(selectedDate),
              provider: simulateDetails.provider,
              value: "",
            },
          ],
          locationMachineDetails: [
            {
              name: "",
              location: "",
              start_time: currentTimeInUtc,
              start_date: convertDateFormat(selectedDate),
              provider: simulateDetails.provider,
              value: "",
            },
          ],
          safetySensorDetails: [
            {
              name: "",
              location: "",
              start_time: currentTimeInUtc,
              start_date: convertDateFormat(selectedDate),
              provider: simulateDetails.provider,
              value: "",
            },
          ],
          cameraSensorDetails: [
            {
              name: "",
              location: "",
              start_time: currentTimeInUtc,
              start_date: convertDateFormat(selectedDate),
              provider: simulateDetails.provider,
              value: "",
            },
          ],
          versionDetails: [
            {
              name: "",
              location: "",
              start_time: currentTimeInUtc,
              start_date: convertDateFormat(selectedDate),
              provider: simulateDetails.provider,
              value: "",
            },
          ],
          domain: "",
        });
      }
    } catch (error: any) {
      setAlertToastType("error");
      setShowAlertToast(true);
      if (error.response) {
        if (error.response.status === 400) {
          setToastMessage(constants.dataMissing);
        }
      } else {
        setToastMessage(constants.ErrorMsg);
      }
      setLoading(false);
    }
  };
  if (loading === true) return <Loader />;

  return (
    <div className="relative">
      <div className="px-10 md:px-24 grid grid-cols-1 my-10">
        <div className="flex justify-between flex-wrap mb-5">
          <div className="flex gap-4 items-center">
            <BackArrow
              className="cursor-pointer"
              onClick={() => navigate(RouteConstants.DASHBOARD)}
            />
            <h1 className="text-lg font-semibold ">
              <span className="text-neutral-500">Provider: </span>
              <span className="text-neutral-900">VIAM</span>
            </h1>
          </div>

          <div className="flex gap-3 justify-center items-center">
            <DropDown
              placeholder="Select Timezone"
              options={timeZoneOptions}
              onChange={handleTimezoneChange}
              value={selectedTimezone}
            />
            <div>
            <DatePicker
              selected={selectedDate}
              onChange={(date: any) => setSelectedDate(date)}
              dateFormat="MMMM d, yyyy"
              minDate={minDate}
              maxDate={maxDate}
              placeholderText="Select a date"
              onKeyDown={(e) => e.preventDefault()}
            />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          {/* ----Payload Card---- */}
          <PayloadCard
            selectedValues={selectedValues}
            removeMachine={removeMachine}
            addMachine={addMachine}
            handleDropdownChange={handleDropdownChange}
            handleMachineDetailsChange={handleMachineDetailsChange}
            timezone={selectedTimezone}

          />

          {/* ----Speed Card---- */}
          <SpeedCard
            selectedValues={selectedValues}
            removeMachine={removeMachine}
            addMachine={addMachine}
            handleDropdownChange={handleDropdownChange}
            handleMachineDetailsChange={handleMachineDetailsChange}
            timezone={selectedTimezone}

          />

          {/* ----Location Card---- */}
          <LocationCard
            selectedValues={selectedValues}
            removeMachine={removeMachine}
            addMachine={addMachine}
            handleDropdownChange={handleDropdownChange}
            handleMachineDetailsChange={handleMachineDetailsChange}
            timezone={selectedTimezone}

          />

          {/* ----Safety Sensor ---- */}
          <SafetyCard
            selectedValues={selectedValues}
            removeMachine={removeMachine}
            addMachine={addMachine}
            handleDropdownChange={handleDropdownChange}
            handleMachineDetailsChange={handleMachineDetailsChange}
            timezone={selectedTimezone}

          />

          {/* ----Camera Sensor ---- */}
          <CameraCard
            selectedValues={selectedValues}
            removeMachine={removeMachine}
            addMachine={addMachine}
            handleDropdownChange={handleDropdownChange}
            handleMachineDetailsChange={handleMachineDetailsChange}
            timezone={selectedTimezone}

          />

          {/* ----Software Version ---- */}
          <VersionCard
            selectedValues={selectedValues}
            removeMachine={removeMachine}
            addMachine={addMachine}
            handleDropdownChange={handleDropdownChange}
            handleMachineDetailsChange={handleMachineDetailsChange}
            timezone={selectedTimezone}

          />
        </div>
        <div className="mt-10">
          <Button
            variant={isSubmitDisabled ? "disable" : "primary"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      {showAlertToast && (
        <div className="fixed bottom-2 left-40 w-full z-50">
          <Toast
            showToast={showAlertToast}
            Message={toastMessage}
            cancelToast={onClickCancelToast}
            type={alertToastType}
          />
        </div>
      )}
    </div>
  );
};

export default RiskTriggerPage;
