import Card from "../../../components/Themecard";
import DropDown from "../../../components/DropDownInput";
import constants from "../../../constants/contents.json";
import Button from "../../../components/Button";
import TimePicker from "../../../components/TimePicker";
import { useState } from "react";
import moment from "moment";
import { UTCToLocalTimes } from "../../../services/utils";
interface IPayloadInterface {
  handleDropdownChange: any;
  handleMachineDetailsChange: any;
  removeMachine: any;
  addMachine: any;
  selectedValues: any;
  timezone:any;

}
const PayloadCard = (props: IPayloadInterface) => {
  const payload = constants.payload;
  const payloadRange = constants.payloadRange;
  const machineRange = constants.machineNames;

  // current time-----------------
  const currentTime = moment().format("HH:mm");

  return (
    <Card>
      <div className="grid grid-cols-2">
        <DropDown
          label={payload.payload}
          placeholder={payload.selectPayload}
          options={payloadRange}
          onChange={(e) =>
            props.handleDropdownChange("payload", e.target.value)
          }
          helpText={payload.details}
        />
      </div>
      {props.selectedValues.payloadMachineDetails.map(
        (machine: any, index: any) => (
          <>
            <div key={index} className="grid grid-cols-4 gap-4 mt-4">
              <DropDown
                label={`Machine *`}
                placeholder="Select Machine"
                options={machineRange}
                onChange={(e) =>
                  props.handleMachineDetailsChange(
                    "payloadMachineDetails",
                    index,
                    "name",
                    e.target.value
                  )
                }
              />
              <DropDown
                label="Zone *"
                placeholder="Select Zone"
                options={constants.zoneRange}
                onChange={(e) =>
                  props.handleMachineDetailsChange(
                    "payloadMachineDetails",
                    index,
                    "location",
                    e.target.value
                  )
                }
              />
              <div>
                <div>
                  <TimePicker
                    label="Select Time"
                    value={machine.start_time}
                    onChange={(e) => {
                      const selectedTime = e.target.value;
                      // const utcTime = moment(selectedTime, "HH:mm")
                      //   .utc()
                      //   .format("HH:mm"); // Convert the selected time to a Moment object and then to UTC
                      props.handleMachineDetailsChange(
                        "payloadMachineDetails",
                        index,
                        "start_time",
                        selectedTime
                      );
                    }}
                  />
                </div>
              </div>
              <div className="mt-8">
                <Button
                  variant="secondary"
                  onClick={() =>
                    props.removeMachine("payloadMachineDetails", index)
                  }
                >
                  Remove
                </Button>
              </div>
            </div>
          </>
        )
      )}
      <div className="mt-5">
        <Button
          variant="primary"
          onClick={() => props.addMachine("payloadMachineDetails")}
        >
          Add Machine
        </Button>
      </div>
    </Card>
  );
};

export default PayloadCard;
