import type { SVGProps } from 'react';
const Warning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.2546 0.999999C8.03034 -0.333333 9.96966 -0.333333 10.7454 0.999999L17.727 13C18.5027 14.3333 17.533 16 15.9816 16H2.01842C0.466954 16 -0.502711 14.3333 0.27302 13L7.2546 0.999999ZM9.99512 5.95134C9.99512 5.39906 9.5474 4.95134 8.99512 4.95134C8.44283 4.95134 7.99512 5.39906 7.99512 5.95134V8.54187C7.99512 9.09415 8.44283 9.54187 8.99512 9.54187C9.5474 9.54187 9.99512 9.09415 9.99512 8.54187V5.95134ZM8.99512 11.5419C8.44283 11.5419 7.99512 11.9896 7.99512 12.5419C7.99512 13.0941 8.44283 13.5419 8.99512 13.5419H9.00512C9.5574 13.5419 10.0051 13.0941 10.0051 12.5419C10.0051 11.9896 9.5574 11.5419 9.00512 11.5419H8.99512Z"
      fill="currentColor"
    />
  </svg>
);
export default Warning;
