import { VariantProps, cva } from "class-variance-authority";
import React, { useState } from "react";
import { cn } from "../../utils";
import ErrorIcon from "../../assets/ErrorIcon";
interface InputProps extends VariantProps<typeof inputVariants> {
  name?: string;
  placeholder?: string;
  label?: String;
  type?: string;
  value?: any;
  helpText?: string;

  onchange?: React.ChangeEventHandler<HTMLInputElement>;
}

const inputVariants = cva(
  "px-4 py-2 w-full h-10 text-base rounded border  outline-none  font-medium font-custom ",
  {
    variants: {
      variant: {
        primary:
          "border-neutral-200  focus-visible:border-primary-600 focus:ring focus:ring-primary-200",
        danger:
          " border-error-700 focus-visible:border-error-700 focus:ring focus:ring-error-200",
        disable: `border-neutral-200 border opacity-50 bg-neutral-100 cursor-not-allowed text-neutral-700 pointer-events-none`,
      },
    },

    defaultVariants: {
      variant: "primary",
    },
  }
);

const Input = ({
  variant,
  name,
  placeholder,
  onchange,
  type,
  value,
  label,
  helpText,
}: InputProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  // Function to transform text by replacing commas with line breaks
  const formatHelpText = (text?: string) => {
    if (!text) return "";
    return text.split(",").map((part, index) => (
      <React.Fragment key={index}>
        {part.trim()}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div>
      <label className="text-neutral-950 text-base font-medium">{label}</label>
      <div className="relative mt-2">
        <input
          value={value}
          onChange={onchange}
          type={type}
          className={cn(inputVariants({ variant }))}
          placeholder={placeholder}
          name={name}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
        {helpText && (
          <div
            className="absolute right-[-30px] top-2 cursor-pointer"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <ErrorIcon className="text-2xl text-neutral-300" />
            {showTooltip && (
              <div className="flex items-center leading-6 absolute z-10 py-3 px-5 right-0 md:left-6 top-2 mb-2 w-[300px]  text-sm text-neutral-950 bg-neutral-0 border border-neutral-200 rounded shadow-lg">
                {formatHelpText(helpText)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
